<!--
 * @Descripttion: 
 * @Author: renmingming
 * @Date: 2022-10-18 14:07:19
 * @LastEditors: renmingming
 * @LastEditTime: 2022-10-18 14:08:43
-->
<template>
  <div class="success-wrap">
    <img src="@/assets/img/cost/success-con.png" alt="" class="success-img" />
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.success-wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../../src/assets/img/cost/success-bg.png) no-repeat;
  background-size: cover;
  .success-img {
    position: absolute;
    display: block;
    width: calc(100vw - 24px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
